import React from 'react'
import BlogDec from '../components/blog/BlogDec'
import BlogPrd from '../components/blog/BlogPrd'

const Blog = () => {
    return (
        <>
            <BlogDec />
            <BlogPrd />
        </>
    )
}

export default Blog
