import React from 'react'
import GrainsDec from '../components/OurPrdGrains/GrainsDec'
import GrainsPrd from '../components/OurPrdGrains/GrainsPrd'

const OurPrdGrains = () => {
    
    return (
        <>
            <GrainsDec />
            <GrainsPrd />
        </>
    )
}

export default OurPrdGrains
