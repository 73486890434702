import React from 'react'
import GalleryDec from '../components/gallery/GalleryDec'
import GalleryPrd from '../components/gallery/GalleryPrd'

const Gallery = () => {
    return (
        <>
            <GalleryDec />
            <GalleryPrd />
        </>
    )
}

export default Gallery
