import React from 'react'
import SpicesDec from '../components/OurPrdSpices/SpicesDec'
import SpicesPrd from '../components/OurPrdSpices/SpicesPrd'

const OurPrdSpices = () => {
    return (
        <>
            <SpicesDec />
            <SpicesPrd />
        </>
    )
}

export default OurPrdSpices
